import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { collection, getDocs, query, where, limit } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useAuth } from "../../../AuthContext";
import { clubDb, functions } from "../../../firebaseConfig";

const Prize = () => {
	const { code } = useParams<{ code: string }>();
	const { currentUser } = useAuth();
	const [prize, setPrize] = useState<{
		prizeName: string;
		userId: string;
		redeemed: boolean;
		redeemedDate?: string;
	} | null>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [redeeming, setRedeeming] = useState(false);

	useEffect(() => {
		const fetchPrize = async () => {
			if (!code) {
				setError("Prize code is missing.");
				setLoading(false);
				return;
			}

			try {
				const prizeRef = collection(clubDb, "prizes");
				const q = query(prizeRef, where("uniqueCode", "==", code), limit(1));
				const docs = await getDocs(q);

				if (docs.empty) {
					setError("Prize not found.");
					return;
				}

				const prizeDoc = docs.docs[0];
				const prizeData = prizeDoc.data();

				if (prizeData) {
					setPrize({
						prizeName: prizeData.prizeName || "Unknown Prize",
						userId: prizeData.userId || "Unknown User",
						redeemed: prizeData.redeemed || false,
						redeemedDate: prizeData.redeemedDate || null,
					});
				} else {
					setError("Prize not found.");
				}
			} catch (err) {
				setError("Failed to fetch prize data.");
			} finally {
				setLoading(false);
			}
		};

		fetchPrize();
	}, [code]);

	const redeemPrize = async () => {
		if (!code || !prize || prize.redeemed || !currentUser) {
			setError("You are not authorized to redeem this prize.");
			return;
		}

		setRedeeming(true);
		try {
			const redeemPrizeCallable = httpsCallable(functions, "redeemPrize");
			const response = await redeemPrizeCallable({ code });

			console.log("Redeem response:", response); // Debugging response

			const data = response.data as {
				message: string;
				prizeId: string;
				redeemedDate: string;
			};

			setPrize((prev) =>
				prev
					? {
						...prev,
						redeemed: true,
						redeemedDate: data.redeemedDate,
					}
					: null
			);

		} catch (err) {
			console.error("Redeem prize error:", err); // Log error for debugging
			setError("Failed to redeem the prize.");
		}
	};

	if (loading) {
		return <div className="flex items-center justify-center h-screen text-lg">Loading...</div>;
	}

	if (error) {
		return (
			<div className="flex items-center justify-center h-screen text-red-600 text-lg">
				{error}
			</div>
		);
	}

	return (
		<div className="flex items-center justify-center h-screen bg-gray-100">
			<div className="bg-white shadow-lg rounded-lg p-8 w-96 text-center">
				<h1 className="text-2xl font-bold mb-4">Prize Details</h1>
				{prize && (
					<>
						<h2 className="text-xl font-semibold mb-2">{prize.prizeName}</h2>
						<p className="text-gray-600 mb-2">Winner: {prize.userId}</p>
						<p className="text-gray-600 mb-4">
							Status:{" "}
							{prize.redeemed
								? `Redeemed on ${new Date(prize.redeemedDate!).toLocaleString()}`
								: "Not Redeemed"}
						</p>
						{!prize.redeemed && currentUser && (
							<button
								onClick={redeemPrize}
								disabled={redeeming}
								className={`${
									redeeming ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
								} text-white py-2 px-4 rounded w-full transition`}
							>
								{redeeming ? "Redeeming..." : "Redeem Prize"}
							</button>
						)}
						{prize.redeemed && (
							<p className="text-green-600 font-medium mt-4">
								This prize has already been redeemed.
							</p>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default Prize;
