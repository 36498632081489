import React, { useEffect, useState } from "react";
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore";
import {firestore, functions, storage} from "../../../firebaseConfig";
import { motion } from "framer-motion";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import {Sauce} from "../../../types";
import axios from "axios";
import firebase from "firebase/compat";
import { httpsCallable } from "firebase/functions"
import Sauces from "./Sauces";

const NewSauce: React.FC = () => {
	const initialSauceState: Sauce = {
		name: "",
		description: "",
		batchSize: 0,
		price: 0,
		thumbnailUrl: "",
	};

	const [formSauce, setFormSauce] = useState<Sauce>(initialSauceState);
	const [thumbnail, setThumbnail] = useState<File | null>(null);
	const [thumbnailPreview, setThumbnailPreview] = useState<string | null>(null);
	const [uploadProgress, setUploadProgress] = useState<number>(0);
	const [existingSauces, setExistingSauces] = useState<Sauce[]>([]);
	const [editingSauceId, setEditingSauceId] = useState<string | null>(null);
	const [products, setProducts] = useState<any[]>([]);

	const handleThumbnailChange = (file: File) => {
		setThumbnail(file);
		setThumbnailPreview(URL.createObjectURL(file));
	};

	useEffect(() => {
		const fetchProducts = async () => {
			try {
				const getAllProducts = httpsCallable(functions, "getAllProducts");
				const response = await getAllProducts() as any;

				const fetchedProducts = response.data.Items;
				setProducts(fetchedProducts);
			} catch (error) {
				console.error("Error fetching products from Firebase function:", error);
			}
		};

		fetchProducts();
	}, []);

	useEffect(() => {
		const fetchSauces = async () => {
			try {
				const saucesSnapshot = await getDocs(collection(firestore, "sauces"));
				const sauces = saucesSnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				})) as Sauce[];
				setExistingSauces(sauces);
			} catch (error) {
				console.error("Error fetching sauces:", error);
			}
		};

		fetchSauces();
	}, []);




	const uploadThumbnail = async (): Promise<string | null> => {
		if (!thumbnail) return null;

		const storageRef = ref(storage, `sauces/${formSauce.name}.jpg`);
		const uploadTask = uploadBytesResumable(storageRef, thumbnail);

		return new Promise((resolve, reject) => {
			uploadTask.on(
				"state_changed",
				(snapshot) => {
					const progress =
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					setUploadProgress(progress);
				},
				(error) => {
					console.error("Thumbnail upload error:", error);
					reject(error);
				},
				async () => {
					const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
					resolve(downloadURL);
				}
			);
		});
	};

	const fetchSauces = async () => {
		try {
			const saucesSnapshot = await getDocs(collection(firestore, "sauces"));
			const sauces = saucesSnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			})) as Sauce[];
			setExistingSauces(sauces);
		} catch (error) {
			console.error("Error fetching sauces:", error);
		}
	};




	const handleThumbnailDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		const file = e.dataTransfer.files?.[0];
		if (file) handleThumbnailChange(file); // Pass File directly
	};

	const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) handleThumbnailChange(file); // Pass File directly
	};

	const removeThumbnail = () => {
		setThumbnail(null);
		setThumbnailPreview(null);
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		try {
			let thumbnailUrl = formSauce.thumbnailUrl;
			if (thumbnail) {
				const uploadedUrl = await uploadThumbnail();
				if (uploadedUrl) thumbnailUrl = uploadedUrl;
			}

			if (editingSauceId) {
				// Update sauce
				const sauceDoc = doc(firestore, "sauces", editingSauceId);
				await updateDoc(sauceDoc, { ...formSauce, thumbnailUrl });
				setEditingSauceId(null);
			} else {
				// Add new sauce
				await addDoc(collection(firestore, "sauces"), { ...formSauce, thumbnailUrl });
			}

			// Refresh sauces
			setFormSauce(initialSauceState);
			setThumbnail(null);
			setThumbnailPreview(null);
			setUploadProgress(0);
			fetchSauces();
		} catch (error) {
			console.error("Error adding or updating sauce:", error);
		}
	};

	const handleEdit = (sauce: any) => {
		setFormSauce({
			name: sauce.name,
			description: sauce.description,
			batchSize: sauce.batchSize,
			price: sauce.price,
			thumbnailUrl: sauce.thumbnailUrl,
		});
		setEditingSauceId(sauce.id);
		setThumbnailPreview(sauce.thumbnailUrl);
	};

	const handleDelete = async (sauceId: string) => {
		try {
			const sauceDoc = doc(firestore, "sauces", sauceId);
			await deleteDoc(sauceDoc);
			setExistingSauces((prev) => prev.filter((sauce) => sauce.id !== sauceId));
		} catch (error) {
			console.error("Error deleting sauce:", error);
		}
	};

	const handleProductChange = (productId: string) => {
		const selectedProduct = products.find((p) => p.ProductID.toString() === productId);
		if (selectedProduct) {
			setFormSauce({
				...formSauce,
				productId: selectedProduct.ProductID.toString(),
				reference: selectedProduct.Reference,
				description: selectedProduct.Description,
				amountExcl: selectedProduct.AmountExcl,
				vat: selectedProduct.VAT,
				unit: selectedProduct.Unit,
				stockQuantity: selectedProduct.StockQuantity,
			});
		}
	};

	return (
		<div className="">
			<div className="flex">
				<div className="w-1/2 mx-auto bg-torch-red-500 py-32">
					<motion.div className="w-full bg-torch-red-500 rounded-xl">
						<div className="text-5xl text-white p-5">
							<h1 className="font-beastie">{editingSauceId ? "EDIT SAUCE" : "NEW SAUCE"}</h1>
						</div>
						<div className="p-4 rounded">
							<form onSubmit={handleSubmit}>
								{/* Thumbnail Upload */}
								<div
									className={`flex flex-col mt-5 border-dashed border-4 p-5 rounded-lg 
                ${thumbnail ? "border-green-500" : "border-white"} 
                hover:border-torch-red-500 transition-all`}
									onDrop={handleThumbnailDrop}
									onDragOver={(e) => e.preventDefault()}
								>
									<label className="font-beastie-bold text-white text-3xl uppercase">
										Thumbnail:
									</label>
									<input
										type="file"
										accept="image/*"
										onChange={handleFileInputChange}
										className="hidden"
										id="thumbnailInput"
									/>
									<label
										htmlFor="thumbnailInput"
										className="cursor-pointer bg-white text-torch-red-500 px-5 py-2 mt-2 rounded shadow"
									>
										Click to Upload or Drag & Drop
									</label>
									{thumbnailPreview && (
										<div className="mt-3 relative">
											<img
												src={thumbnailPreview}
												alt="Thumbnail Preview"
												className="w-1/3 object-cover rounded"
											/>
											<button
												className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 rounded"
												onClick={removeThumbnail}
												type="button"
											>
												Remove
											</button>
										</div>
									)}
								</div>


								{/* Name Field */}
								<div className="flex flex-col mt-5">
									<label className="font-beastie-bold text-white text-3xl uppercase">
										Name:
									</label>
									<input
										type="text"
										placeholder="Sauce Name"
										value={formSauce.name}
										onChange={(e) =>
											setFormSauce({...formSauce, name: e.target.value})
										}
										className="border p-2 rounded mb-2"
									/>
								</div>

								{/* Description Field */}
								<div className="flex flex-col mt-5">
									<label className="font-beastie-bold text-white text-3xl uppercase">
										Description:
									</label>
									<textarea
										placeholder="Description"
										value={formSauce.description}
										onChange={(e) =>
											setFormSauce({...formSauce, description: e.target.value})
										}
										className="border p-2 rounded mb-2"
									/>
								</div>

								{/* Batch Size Field */}
								<div className="flex flex-col mt-5">
									<label className="font-beastie-bold text-white text-3xl uppercase">
										Batch Size:
									</label>
									<input
										type="number"
										placeholder="Batch Size"
										value={formSauce.batchSize}
										onChange={(e) =>
											setFormSauce({...formSauce, batchSize: Number(e.target.value)})
										}
										className="border p-2 rounded mb-2"
									/>
								</div>

								{/* Price Field */}
								<div className="flex flex-col mt-5">
									<label className="font-beastie-bold text-white text-3xl uppercase">
										Price:
									</label>
									<input
										type="number"
										step=".01"
										placeholder="Price"
										value={formSauce.price}
										onChange={(e) =>
											setFormSauce({...formSauce, price: Number(e.target.value)})
										}
										className="border p-2 rounded mb-2"
									/>
								</div>

								<label htmlFor="products">Select Product:</label>
								<select
									id="products"
									value={formSauce.productId}
									onChange={(e) => handleProductChange(e.target.value)}
									className="border p-2 rounded"
								>
									<option value="">-- Select Product --</option>
									{products.map((product) => (
										<option key={product.ProductID} value={product.ProductID}>
											{product.Reference}
										</option>
									))}
								</select>

								{/* Upload Progress */}
								{uploadProgress > 0 && (
									<div className="mt-3">
										<label className="text-white font-beastie-bold">
											Upload Progress: {uploadProgress.toFixed(0)}%
										</label>
										<div className="w-full bg-white rounded-full h-2.5 border">
											<div
												className="bg-torch-red-500 h-2 rounded-full"
												style={{width: `${uploadProgress}%`}}
											></div>
										</div>
									</div>
								)}


								<button
									type="submit"
									className="hover:bg-torch-red-500 py-3 px-10 w-auto rounded hover:text-white text-3xl font-beastie-bold bg-white m-5 shadow text-torch-red-500 transition-all cursor-pointer"
								>
									{editingSauceId ? "Update Sauce" : "Add Sauce"}
								</button>
							</form>
						</div>
					</motion.div>
				</div>

				{/* Existing Sauces Section */}
				<div className="w-1/2 py-32 px-5">
					<h2 className="text-3xl font-beastie-bold text-torch-red-500 mb-5">
						Existing Sauces
					</h2>
					<ul className="space-y-4">
						{existingSauces.map((sauce) => (
							<li
								key={sauce.id}
								className="flex items-center bg-white shadow rounded p-3"
							>
								<img
									src={sauce.thumbnailUrl}
									alt={sauce.name}
									className="w-16 h-16 object-cover rounded mr-3"
								/>
								<div className="flex-grow">
									<h3 className="font-bold text-lg">{sauce.name}</h3>
									<p className="text-sm">{sauce.description}</p>
								</div>
								<button
									className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
									onClick={() => handleEdit(sauce)}
								>
									Edit
								</button>
								<button
									className="bg-red-500 text-white px-4 py-2 rounded"
									onClick={() => handleDelete(sauce.id!)}
								>
									Delete
								</button>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default NewSauce;
