import {Route, Routes, useLocation} from "react-router-dom";
import React, {lazy, Suspense} from "react";
import {AnimatePresence} from "framer-motion";
import BeastieLoader from "./Loader/Loader";
import PrivacyPolicy from "../pages/Privacy";


const Home = React.lazy(() => import("../pages/Home/Home"));
const Posters = React.lazy(() => import("../pages/Posters/Posters"));
const FullMenu = React.lazy(() => import("../pages/FullMenu/FullMenu"));
const Mechelen = React.lazy(() => import("../pages/FullMenu/Mechelen"));
const Smash = React.lazy(() => import("../pages/FullMenu/Smash"));
const Antwerp = React.lazy(() => import("../pages/FullMenu/Antwerp"));
const Franchise = React.lazy(() => import("../pages/Franchise/Franchise"));
const Restaurants = React.lazy(() => import("../pages/Restaurants/Restaurants"));
const Community = React.lazy(() => import("../pages/Community/Community"));
const OurStory = React.lazy(() => import("../pages/OurStory/OurStory"));
const Jobs = React.lazy(() => import("../pages/Jobs/Jobs"));
const Style = React.lazy(() => import("../pages/Style/Style"));
const Press = React.lazy(() => import("../pages/Press/Press"));
const Kiosk = React.lazy(() => import("../pages/Kiosk/Kiosk"));
const Events = React.lazy(() => import("../pages/Events/Events"));
const Giveaway = React.lazy(() => import("../pages/Giveaway/Giveaway"));
const BlogSingle = React.lazy(() => import("../pages/BlogSingle/BlogSingle"));
const Menu = React.lazy(() => import("../pages/Menu/Menu"));



function AnimatedRoutes() {

    const location = useLocation();

    return (
       <AnimatePresence>
           <Suspense fallback={<BeastieLoader />}>
               <Routes location={location} key={location.pathname}>
                   <Route path="/" element={<Home />} />
                   <Route path="/menu" element={<Posters />} />
                   <Route path="/menu/select" element={<FullMenu />} />
                   <Route path="/menu/:menuName" element={<Menu />} />
                   {/*<Route path="/menu/mechelen" element={<Mechelen />} />*/}
                   {/*<Route path="/menu/smash" element={<Smash />} />*/}
                   {/*<Route path="/menu/antwerp" element={<Antwerp />} />*/}
                   <Route path="/franchise" element={<Franchise />} />
                   <Route path="/restaurants" element={<Restaurants />} />
                   <Route path="/community" element={<Community />} />
                   <Route path="/story" element={<OurStory />} />
                   <Route path="/jobs" element={<Jobs />} />
                   <Route path="/style" element={<Style />} />
                   <Route path="/press" element={<Press />} />
                   <Route path="/kiosk" element={<Kiosk />} />
                   <Route path="/events" element={<Events />} />
                   <Route path="/giveaway" element={<Giveaway />} />
                   <Route path="/press/:id" element={<BlogSingle />} />
                   <Route path="/privacy/" element={<PrivacyPolicy />} />
               </Routes>
           </Suspense>
       </AnimatePresence>


    )
}


export default AnimatedRoutes;
