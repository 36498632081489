// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";


export const firebaseConfig = {
	apiKey: "AIzaSyAl88BuKirXgwRf94XCOlRI29pMRh0Fe1U",
	authDomain: "beastie-burgers.firebaseapp.com",
	projectId: "beastie-burgers",
	storageBucket: "beastie-burgers.appspot.com",
	messagingSenderId: "187162761438",
	appId: "1:187162761438:web:392404ebf38c32feedca4f",
	measurementId: "G-FZK9BS3JED"
};

export const clubFirebaseConfig = {
	apiKey: "AIzaSyCEy1apPSlP1um-FpXiqjH6BPqtvXV6IXc",
	authDomain: "beastie-club.firebaseapp.com",
	projectId: "beastie-club",
	storageBucket: "beastie-club.firebasestorage.app",
	messagingSenderId: "461665374827",
	appId: "1:461665374827:web:475bdc7eaa26f1626c972e",
	measurementId: "G-K7301YSZ52"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);


const club = initializeApp(clubFirebaseConfig, "clubApp");
const clubDb = getFirestore(club);

export { app, auth, firestore, storage, analytics, functions, clubDb };
