import React, { useState, useEffect, FormEvent, ChangeEvent, DragEvent, useRef } from "react";
import { motion } from "framer-motion";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
	collection,
	addDoc,
	getDocs,
	query,
	orderBy,
} from "firebase/firestore";
import { firestore, storage } from "../../../firebaseConfig";

interface FirmwareData {
	id?: string;
	version: string;
	downloadUrl: string;
	uploadDate: string;
}

const Firmware: React.FC = () => {
	const [version, setVersion] = useState<string>("");
	const [file, setFile] = useState<File | null>(null);
	const [uploading, setUploading] = useState<boolean>(false);
	const [firmwareList, setFirmwareList] = useState<FirmwareData[]>([]);
	const [alert, setAlert] = useState<{ open: boolean; type: string; message: string }>({
		open: false,
		type: "",
		message: ""
	});
	const [isDragging, setIsDragging] = useState<boolean>(false);
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	// Function to fetch firmware records from Firestore
	const fetchFirmwareList = async () => {
		try {
			const firmwareRef = collection(firestore, "firmware");
			const q = query(firmwareRef, orderBy("uploadDate", "desc"));
			const querySnapshot = await getDocs(q);
			const list: FirmwareData[] = [];
			querySnapshot.forEach((doc) => {
				list.push({ id: doc.id, ...(doc.data() as FirmwareData) });
			});
			setFirmwareList(list);
		} catch (error: any) {
			setAlert({ open: true, type: "error", message: error.message });
		}
	};

	useEffect(() => {
		fetchFirmwareList();
	}, []);

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			setFile(e.target.files[0]);
		} else {
			setFile(null);
		}
	};

	const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragging(true);
	};

	const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragging(false);
	};

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragging(false);
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			setFile(e.dataTransfer.files[0]);
			// Clear drag data
			e.dataTransfer.clearData();
		}
	};

	const openFileDialog = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	// Handle the form submission
	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();
		if (!version || !file) {
			setAlert({ open: true, type: "warning", message: "Version and file are required." });
			return;
		}
		setUploading(true);

		try {
			// Create a reference in Firebase Storage within the "firmware" folder
			const storageRef = ref(storage, `firmware/${file.name}`);
			// Start the upload
			const uploadTask = uploadBytesResumable(storageRef, file);

			uploadTask.on(
				"state_changed",
				(snapshot) => {
					// Optionally, track progress here
					// const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				},
				(error) => {
					setAlert({ open: true, type: "error", message: error.message });
					setUploading(false);
				},
				async () => {
					// Once the upload is complete, get the download URL
					const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
					// Save the firmware data in Firestore
					await addDoc(collection(firestore, "firmware"), {
						version,
						downloadUrl,
						uploadDate: new Date().toISOString(),
					});
					setAlert({ open: true, type: "success", message: "Firmware uploaded successfully!" });
					setVersion("");
					setFile(null);
					fetchFirmwareList();
					setUploading(false);
				}
			);
		} catch (error: any) {
			setAlert({ open: true, type: "error", message: error.message });
			setUploading(false);
		}
	};

	return (
		<div className="min-h-screen bg-white text-gray-900 p-6 w-[50%] mx-auto pt-24">
			<motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }}>
				<h1 className="text-3xl font-bold text-torch-red-500">Firmware Management</h1>
			</motion.div>

			<motion.form
				onSubmit={handleSubmit}
				initial={{ opacity: 0, scale: 0.95 }}
				animate={{ opacity: 1, scale: 1 }}
				className="bg-gray-100 p-6 rounded-lg mt-6"
			>
				<label className="block text-lg font-semibold">Firmware Version</label>
				<input
					type="text"
					value={version}
					onChange={(e) => setVersion(e.target.value)}
					className="w-full p-2 mt-2 border rounded-md focus:ring-torch-red-500 focus:border-torch-red-500"
					placeholder="e.g., 1.0.2"
				/>

				<label className="block mt-4 text-lg font-semibold">Upload Firmware File</label>
				<div
					onDragOver={handleDragOver}
					onDragLeave={handleDragLeave}
					onDrop={handleDrop}
					onClick={openFileDialog}
					className={`mt-2 p-6 border-2 border-dashed rounded-md cursor-pointer text-center transition 
            ${isDragging ? "border-torch-red-500 bg-gray-200" : "border-gray-300 hover:border-torch-red-500"}`}
				>
					{file ? (
						<p className="text-lg">{file.name}</p>
					) : (
						<p className="text-lg text-gray-500">Drag and drop your .bin file here, or click to select</p>
					)}
					<input
						type="file"
						accept=".bin"
						onChange={handleFileChange}
						ref={fileInputRef}
						className="hidden"
					/>
				</div>

				<button
					type="submit"
					disabled={uploading}
					className="mt-4 w-full bg-torch-red-500 text-white p-2 rounded-md hover:bg-torch-red-600 transition"
				>
					{uploading ? "Uploading..." : "Upload Firmware"}
				</button>
			</motion.form>

			<motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }}>
				<h2 className="text-2xl font-semibold mt-8">Previous Versions</h2>
				<div className="bg-gray-100 p-6 rounded-lg mt-4">
					{firmwareList.length === 0 ? (
						<p>No firmware versions found.</p>
					) : (
						<table className="w-full border-collapse border border-gray-300">
							<thead>
							<tr className="bg-gray-300">
								<th className="p-2">Version</th>
								<th className="p-2">Upload Date</th>
								<th className="p-2">Download</th>
							</tr>
							</thead>
							<tbody>
							{firmwareList.map((fw) => (
								<tr key={fw.id} className="border-t">
									<td className="p-2 text-center">{fw.version}</td>
									<td className="p-2 text-center">{new Date(fw.uploadDate).toLocaleString()}</td>
									<td className="p-2 text-center">
										<a
											href={fw.downloadUrl}
											target="_blank"
											rel="noopener noreferrer"
											className="text-torch-red-500 hover:underline"
										>
											Download
										</a>
									</td>
								</tr>
							))}
							</tbody>
						</table>
					)}
				</div>
			</motion.div>
		</div>
	);
};

export default Firmware;
