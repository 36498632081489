import React, { useEffect, useState } from "react";
import { useAuth } from "../../../AuthContext";
import SauceItem from "./SauceItem";
import { collection, getDocs, doc, setDoc, getDoc } from "firebase/firestore";
import { firestore } from "../../../firebaseConfig";
import { Sauce } from "../../../types";
import { FaShoppingCart } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const Sauces = () => {
	const { currentUser } = useAuth();
	const [sauces, setSauces] = useState<Sauce[]>();
	const [cart, setCart] = useState<{ [key: string]: number }>({});
	const [cartVisible, setCartVisible] = useState(false);

	// Fetch all sauces
	useEffect(() => {
		const fetchSauces = async () => {
			try {
				const saucesSnapshot = await getDocs(collection(firestore, "sauces"));
				const saucesDoc = saucesSnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				})) as Sauce[];
				setSauces(saucesDoc);
			} catch (error) {
				console.error("Error fetching sauces:", error);
			}
		};

		fetchSauces();
	}, []);

	// Fetch cart for the logged-in user
	useEffect(() => {
		const fetchCart = async () => {
			if (!currentUser) return;

			try {
				const cartRef = doc(firestore, "carts", currentUser.uid);
				const cartSnapshot = await getDoc(cartRef);
				if (cartSnapshot.exists()) {
					setCart(cartSnapshot.data().items || {});
				}
			} catch (error) {
				console.error("Error fetching cart:", error);
			}
		};

		fetchCart();
	}, [currentUser]);

	// Update cart in Firestore
	const updateCart = async (sauceId: string, quantity: number) => {
		if (!currentUser) {
			alert("You need to be logged in to add to the cart.");
			return;
		}

		try {
			const cartRef = doc(firestore, "carts", currentUser.uid);
			const newCart = { ...cart, [sauceId]: quantity };

			if (quantity === 0) {
				delete newCart[sauceId]; // Remove item if quantity is 0
			}

			await setDoc(cartRef, { items: newCart }, { merge: true });
			setCart(newCart);
		} catch (error) {
			console.error("Error updating cart:", error);
		}
	};

	// Clear cart
	const clearCart = async () => {
		if (!currentUser) {
			alert("You need to be logged in to clear the cart.");
			return;
		}

		try {
			const cartRef = doc(firestore, "carts", currentUser.uid);
			await setDoc(cartRef, { items: {} }, { merge: true });
			setCart({});
		} catch (error) {
			console.error("Error clearing cart:", error);
		}
	};

	// Calculate total quantity and price
	const getCartSummary = () => {
		let totalQuantity = 0;
		let totalPrice = 0;

		Object.keys(cart).forEach((sauceId) => {
			const sauce = sauces?.find((s) => s.id === sauceId);
			if (sauce) {
				totalQuantity += cart[sauceId];
				totalPrice += cart[sauceId] * sauce.price;
			}
		});

		return { totalQuantity, totalPrice };
	};

	const { totalQuantity, totalPrice } = getCartSummary();

	return (
		<div>
			{/* Main Content */}
			<div className="pt-36 pb-20">
				{currentUser?.role === "admin" && (
					<div className="w-[85%] mx-auto flex justify-center items-center">
						<a
							href="/admin/new/sauce"
							className="flex gap-2 text-2xl mt-4 border p-3 rounded cursor-pointer hover:border-torch-red-500 transition-all"
						>
							<div className="font-beastie-bold tracking-wide">New Sauce</div>
						</a>
					</div>
				)}

				<div className="w-[85%] mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 my-10">
					{sauces?.length !== 0 &&
						sauces?.map((sauce) => (
							<SauceItem
								key={sauce.id}
								sauce={sauce}
								quantity={cart[sauce.id!] || 0} // Pass current quantity
								onUpdateCart={updateCart} // Pass updateCart function
							/>
						))}
				</div>
			</div>

			{/* Cart Button */}
			<div className="fixed bottom-4 right-4 z-50">
				<motion.div
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
					transition={{ duration: 0.3 }}
				>
					<button
						className="relative w-14 h-14 p-4 bg-black rounded-full flex items-center justify-center shadow-lg text-white"
						onClick={() => setCartVisible(!cartVisible)}
					>
						<FaShoppingCart size={16} />
						{totalQuantity > 0 && (
							<div className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-6 h-6 flex items-center justify-center">
								{totalQuantity}
							</div>
						)}
					</button>
				</motion.div>
			</div>

			{/* Cart Modal */}
			<AnimatePresence>
				{cartVisible && (
					<motion.div
						initial={{ opacity: 0, scale: 0.9 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.9 }}
						transition={{ duration: 0.3 }}
						className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center"
					>
						<div className="bg-white w-full max-w-lg p-6 rounded shadow-lg">
							<h2 className="text-lg font-bold mb-4">Your Cart</h2>
							{Object.keys(cart).length > 0 ? (
								<div className="space-y-4">
									{Object.keys(cart).map((sauceId) => {
										const sauce = sauces?.find((s) => s.id === sauceId);
										if (!sauce) return null;

										return (
											<div
												key={sauceId}
												className="flex justify-between items-center"
											>
												<div>
													<p className="font-medium">{sauce.name}</p>
													<p className="text-sm text-gray-600">
														{cart[sauceId]} x {sauce.price}€
													</p>
												</div>
												<div className="flex items-center gap-2">
													<button
														className="text-red-500"
														onClick={() => updateCart(sauceId, 0)}
													>
														Remove
													</button>
													<p className="font-medium">
														{(cart[sauceId] * sauce.price).toFixed(2)}€
													</p>
												</div>
											</div>
										);
									})}

									<div className="flex justify-between mt-4">
										<p className="font-bold">Total: {totalPrice.toFixed(2)}€</p>
										<a
											href="/admin/sauce/checkout"
											className="bg-green-500 text-white py-2 px-4 rounded"
										>
											Checkout
										</a>
									</div>
								</div>
							) : (
								<p>Your cart is empty.</p>
							)}

							{/* Actions */}
							<div className="mt-4 flex justify-between">
								<button
									className="bg-red-500 text-white py-2 px-4 rounded"
									onClick={clearCart}
								>
									Clear Cart
								</button>
								<button
									className="bg-gray-500 text-white py-2 px-4 rounded"
									onClick={() => setCartVisible(false)}
								>
									Close
								</button>
							</div>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default Sauces;
