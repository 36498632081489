import React, { useRef, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import Footer from "./components/Footer/Footer";
import FloatingMenu from "./components/FloatingMenu/FloatingMenu";
import { AuthProvider } from "./AuthContext";
import AdminHome from "./pages/admin/Home/Home";
import Login from "./pages/admin/Login/Login";
import ProtectedRoute from "./ProtectedRoute";
import {default as PressAdmin} from "./pages/admin/Press/Press";
import AdminJobs from "./pages/admin/Jobs/AdminJobs";
import AdminMenu from "./pages/admin/Menu/AdminMenu";
import Navbar from "./components/navbar";
import AnimatedRoutes from "./components/AnimatedRoutes";
import AdminGiveaway from "./pages/admin/Giveaway/AdminGiveaway";
import ScrollToTop from "./components/ScrollToTop";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import Register from "./pages/admin/Register/Register";
import Sauces from "./pages/admin/Sauces/Sauces";
import NewSauce from "./pages/admin/Sauces/NewSauce";
import Checkout from "./pages/admin/Sauces/Checkout";
import Prize from "./pages/admin/Prize/Prize";
import {CookieConsent} from "react-cookie-consent";
import Firmware from "./pages/admin/Firmware/Firmware";

function App() {
	const footerRef = useRef(null);
	const [isFooterVisible, setFooterVisible] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setFooterVisible(entry.isIntersecting);

			},
			{ threshold: 0.1 }
		);

		if (footerRef.current) {
			observer.observe(footerRef.current);
		}

		return () => {
			if (footerRef.current) {
				observer.unobserve(footerRef.current);
			}
		};
	}, []);

	return (
		<div className="App">
			<ParallaxProvider>
				<SpeedInsights/>
				<Analytics />
				<BrowserRouter>
					<AuthProvider>
						<ScrollToTop />
						<Navbar />
						<AnimatedRoutes />
						<Routes>
							<Route path="/admin/login" element={<Login />} />
							<Route element={<ProtectedRoute />}>
								<Route path="/admin" element={<AdminHome />} />
								<Route path="/admin/press" element={<PressAdmin />} />
								<Route path="/admin/jobs" element={<AdminJobs />} />
								<Route path="/admin/menu" element={<AdminMenu />} />
								<Route path="/admin/giveaway" element={<AdminGiveaway />} />
								<Route path="/admin/register" element={<Register />} />
								<Route path="/admin/sauces" element={<Sauces />} />
								<Route path="/admin/new/sauce" element={<NewSauce />} />
								<Route path="/admin/sauce/checkout" element={<Checkout />} />
								<Route path="admin/prize/:code" element={<Prize />} />
								<Route path="admin/firmware/" element={<Firmware />} />
							</Route>
						</Routes>

						<div ref={footerRef}>
							<Footer />
						</div>
					</AuthProvider>
				</BrowserRouter>
			</ParallaxProvider>
			<FloatingMenu isFooterVisible={isFooterVisible} />
			{/* CookieConsent Banner */}
			<CookieConsent
				location="bottom"
				buttonText="I accept"
				cookieName="beastieCookieConsent"
				style={{ background: "#2B373B" }}
				buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
				expires={150}
			>
				This website uses cookies to enhance your experience.{" "}
				<a
					href="/privacy"
					style={{ color: "#fff", textDecoration: "underline" }}
				>
					Learn more
				</a>
			</CookieConsent>
		</div>
	);
}

export default App;
