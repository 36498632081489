import React from 'react';
import CookieConsent from "react-cookie-consent";

const PrivacyPolicy: React.FC = () => {
	// Use today's date (adjust as needed)
	const effectiveDate = "March 21, 2025";
	const lastUpdated = "March 21, 2025";

	return (
		<div className="min-h-screen bg-gray-50">
			<div className="max-w-4xl mx-auto px-4 py-12 leading-relaxed font-sans">
				{/* Overall Header */}
				<header className="text-center mb-12">
					<h1 className="text-5xl font-extrabold mb-4">Privacy Policies</h1>
					<p className="text-gray-600">
						<strong>Effective Date:</strong> {effectiveDate} | <strong>Last Updated:</strong> {lastUpdated}
					</p>
				</header>

				{/* Beastie.be Privacy Policy */}
				<section className="mb-12">
					<h2 className="text-3xl font-bold mb-4">Beastie.be Privacy Policy</h2>
					<p className="mb-4">
						At Beastie.be, we respect your privacy and are committed to protecting your personal information.
						Our website does not collect any personal data from visitors. The only data automatically received is through the use of cookies,
						which are used to enhance your experience.
					</p>
					<p className="mb-4">
						We use cookies for functionality and analytics purposes, ensuring that our website works as intended and to help us understand usage.
						You can manage or disable cookies through your browser settings, though this may affect the website's functionality.
					</p>
					<p className="mb-4">
						For more details on our practices, please review the policies provided below.
					</p>
				</section>

				{/* Beastie Club Privacy Policy */}
				<section id="BeastieClub" className="mb-12">
					<h2 className="text-3xl font-bold mb-4">Beastie Club Privacy Policy for Beastie Club App</h2>
					<p className="mb-2"><strong>Effective Date:</strong> {effectiveDate}</p>
					<p className="mb-4"><strong>Last Updated:</strong> {lastUpdated}</p>

					<h3 className="text-2xl font-semibold mb-2">1. Introduction</h3>
					<p className="mb-4">
						Welcome to the Beastie Club App, operated by Beastie Burgers. We respect your privacy and are committed to protecting your personal data.
						This Privacy Policy explains what information we collect, how we use it, and your rights regarding your data.
					</p>

					<h3 className="text-2xl font-semibold mb-2">2. Information We Collect</h3>
					<p className="mb-2"><strong>Personal Data:</strong> Name, email, phone number (if provided voluntarily).</p>
					<p className="mb-2"><strong>Usage Data:</strong> How you interact with the app (e.g., spins, rewards, visits).</p>
					<p className="mb-4"><strong>Device Data:</strong> Type of device, operating system, and app performance analytics.</p>

					<h3 className="text-2xl font-semibold mb-2">3. How We Use Your Data</h3>
					<ul className="list-disc list-inside mb-4 ml-4">
						<li>Provide app functionality (Spin &amp; Win, rewards).</li>
						<li>Improve user experience.</li>
						<li>Send promotional offers (if opted in).</li>
						<li>Prevent fraud and ensure app security.</li>
					</ul>

					<h3 className="text-2xl font-semibold mb-2">4. Sharing Your Data</h3>
					<p className="mb-2">
						We do not sell your data. We may share information with:
					</p>
					<ul className="list-disc list-inside mb-4 ml-4">
						<li>Service providers (e.g., analytics, app maintenance).</li>
						<li>Legal authorities if required by law.</li>
					</ul>

					<h3 className="text-2xl font-semibold mb-2">5. User Rights</h3>
					<ul className="list-disc list-inside mb-4 ml-4">
						<li>Access, update, or delete your personal data.</li>
						<li>Opt-out of promotional messages.</li>
						<li>Request a copy of your stored data.</li>
					</ul>

					<h3 className="text-2xl font-semibold mb-2">6. Security</h3>
					<p className="mb-4">
						We implement industry-standard security measures, but no system is 100% secure. Please use the app responsibly.
					</p>

					<h3 className="text-2xl font-semibold mb-2">7. Changes to This Policy</h3>
					<p className="mb-4">
						We may update this policy. You will be notified of major changes within the app.
					</p>

					<h3 className="text-2xl font-semibold mb-2">8. Contact Us</h3>
					<p>
						For privacy concerns, contact us at:{" "}
						<a href="mailto:info@beastie.be" className="text-blue-500 underline">
							info@beastie.be
						</a>
					</p>
				</section>
			</div>

			{/* Cookie Consent Banner */}
			<CookieConsent
				location="bottom"
				buttonText="I accept"
				cookieName="beastieCookieConsent"
				style={{ background: "#2B373B" }}
				buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
				expires={150}
			>
				This website uses cookies to enhance your experience.{" "}
				<a href="#BeastieClub" className="text-white underline">
					Learn more
				</a>
			</CookieConsent>
		</div>
	);
};

export default PrivacyPolicy;
