import React, { useEffect, useState } from "react";
import { useAuth } from "../../../AuthContext"; // Context to get the current user
import {firestore, functions} from "../../../firebaseConfig"; // Firestore configuration
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { motion } from "framer-motion";
import { Sauce } from "../../../types";
import axios from "axios";
import {httpsCallable} from "firebase/functions"; // For making API requests

// Type Definitions
interface CartItems {
	[key: string]: number; // sauceId -> quantity
}

const Checkout: React.FC = () => {
	const { currentUser } = useAuth();
	const [cart, setCart] = useState<CartItems>({});
	const [sauces, setSauces] = useState<Sauce[]>([]);
	const [isProcessing, setIsProcessing] = useState(false);
	const [loading, setLoading] = useState(true);

	// Fetch cart and sauces data
	useEffect(() => {
		const fetchCartAndSauces = async () => {
			if (!currentUser) return;

			try {
				// Fetch cart data for the logged-in user
				const cartRef = doc(firestore, "carts", currentUser.uid);
				const cartSnapshot = await getDoc(cartRef);
				const cartData: CartItems = cartSnapshot.exists()
					? (cartSnapshot.data().items as CartItems) || {}
					: {};

				// Fetch all sauces
				const saucesSnapshot = await getDocs(collection(firestore, "sauces"));
				const saucesData: Sauce[] = saucesSnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				})) as Sauce[];

				setCart(cartData);
				setSauces(saucesData);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching cart and sauces:", error);
			}
		};

		fetchCartAndSauces();
	}, [currentUser]);

	// Calculate total price and tax
	const calculateTotal = (): { total: string; tax: string } => {
		let total = 0;
		let totalTax = 0;

		Object.keys(cart).forEach((sauceId) => {
			const sauce = sauces.find((s) => s.id === sauceId);
			if (sauce) {
				const price = cart[sauceId] * sauce.price;
				const tax = price * 0.06; // Assuming 6% VAT
				total += price;
				totalTax += tax;
			}
		});

		return { total: total.toFixed(2), tax: totalTax.toFixed(2) };
	};

	const handleCheckout = async () => {
		setIsProcessing(true);

		try {
			// Cloud Function: createOrder
			const createOrder = httpsCallable(functions, "createOrder");
			const orderResponse = await createOrder({
				customerId: currentUser?.uid,
				items: Object.keys(cart).map((sauceId) => ({
					itemId: sauceId,
					quantity: cart[sauceId],
				})),
			});

			// Extract order ID from response
			const { orderId } = orderResponse.data as { orderId: string };

			// Cloud Function: generateInvoice
			const generateInvoice = httpsCallable(functions, "generateInvoiceFromOrder");
			const invoiceResponse = await generateInvoice({ orderId });

			// Handle success and PDF URL
			const { pdfUrl } = invoiceResponse.data as { pdfUrl: string };
			alert("Order placed successfully! Invoice generated.");
			window.open(pdfUrl, "_blank"); // Open the PDF in a new tab
		} catch (error) {
			console.error("Error processing checkout:", error);
			alert("Failed to process the order. Please try again.");
		} finally {
			setIsProcessing(false);
		}
	};

	if (loading) {
		return (
			<div className="min-h-screen flex justify-center items-center bg-gray-100">
				<p className="text-lg font-medium text-gray-700">Loading...</p>
			</div>
		);
	}

	return (
		<div className="min-h-screen bg-gray-100 pt-24">
			<div className="max-w-6xl mx-auto flex gap-8 bg-white shadow-lg rounded-lg p-6">
				{/* Cart Items Section */}
				<div className="w-2/3">
					<h2 className="text-2xl font-bold text-gray-800 mb-6">Your Cart</h2>
					<div className="space-y-6">
						{Object.keys(cart).length > 0 ? (
							Object.keys(cart).map((sauceId) => {
								const sauce = sauces.find((s) => s.id === sauceId);
								if (!sauce) return null;

								return (
									<div
										key={sauceId}
										className="flex justify-between items-center border-b pb-4"
									>
										<div className="flex items-center gap-4">
											<img
												src={sauce.thumbnailUrl || "/placeholder-image.png"}
												alt={sauce.name}
												className="w-16 h-16 object-cover rounded"
											/>
											<div>
												<p className="font-medium text-gray-800">{sauce.name}</p>
												<p className="text-sm text-gray-500">
													{cart[sauceId]} x {sauce.price}€
												</p>
											</div>
										</div>
										<p className="font-medium text-gray-800">
											{(cart[sauceId] * sauce.price).toFixed(2)}€
										</p>
									</div>
								);
							})
						) : (
							<p className="text-gray-600">Your cart is empty.</p>
						)}
					</div>
				</div>

				{/* Personal Info Section */}
				<div className="w-1/3 bg-gray-50 p-6 rounded-lg border">
					<h2 className="text-xl font-bold text-gray-800 mb-4">Your Information</h2>
					<div className="space-y-4">
						{/* Basic Information */}
						<div>
							<p className="text-sm text-gray-500">Email</p>
							<p className="text-gray-800 font-medium">{currentUser?.email}</p>
						</div>
						<div>
							<p className="text-sm text-gray-500">Role</p>
							<p className="text-gray-800 font-medium">{currentUser?.role || "N/A"}</p>
						</div>
						<div>
							<p className="text-sm text-gray-500">Restaurant</p>
							<p className="text-gray-800 font-medium">
								{currentUser?.restaurant || "N/A"}
							</p>
						</div>

						{/* Additional Information */}
						<div>
							<p className="text-sm text-gray-500">Street</p>
							<p className="text-gray-800 font-medium">{currentUser?.street || "N/A"}</p>
						</div>
						<div>
							<p className="text-sm text-gray-500">Postal Code</p>
							<p className="text-gray-800 font-medium">{currentUser?.postalNumber || "N/A"}</p>
						</div>
						<div>
							<p className="text-sm text-gray-500">City</p>
							<p className="text-gray-800 font-medium">{currentUser?.city || "N/A"}</p>
						</div>
						<div>
							<p className="text-sm text-gray-500">Country</p>
							<p className="text-gray-800 font-medium">{currentUser?.country || "N/A"}</p>
						</div>
						<div>
							<p className="text-sm text-gray-500">VAT Number</p>
							<p className="text-gray-800 font-medium">{currentUser?.VATNumber || "N/A"}</p>
						</div>

						<div className="border-t pt-4">
							<p className="text-sm text-gray-500">Total without VAT</p>
							<p className="text-gray-800 font-semibold text-lg">{calculateTotal().total}€</p>
						</div>
						<div className="pt-2">
							<p className="text-sm text-gray-500">Total with VAT</p>
							<p className="text-gray-800 font-semibold text-lg">
								{(parseFloat(calculateTotal().total) + parseFloat(calculateTotal().tax)).toFixed(2)}€
							</p>
						</div>
					</div>
					<motion.button
						whileHover={{scale: 1.05}}
						whileTap={{scale: 0.95}}
						className={`w-full py-3 mt-6 rounded text-white font-medium transition ${
							isProcessing ? "bg-gray-500" : "bg-green-500 hover:bg-green-600"
						}`}
						onClick={handleCheckout}
						disabled={isProcessing}
					>
						{isProcessing ? "Processing..." : "Place Order"}
					</motion.button>
				</div>
			</div>
		</div>
	);
};

export default Checkout;
