import React, { createContext, useContext, useEffect, useState, ReactNode } from "react";
import { User, onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import {auth, firestore} from "./firebaseConfig";
import {ExtendedUser, Party} from "./types"; // Import Party interface

// Define the structure for the extended currentUser

interface AuthContextType {
	currentUser: ExtendedUser | null; // Use ExtendedUser here
	loading: boolean;
}

interface AuthProviderProps {
	children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [currentUser, setCurrentUser] = useState<ExtendedUser | null>(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			if (user) {
				// Fetch user details from Firestore
				const userDocRef = doc(firestore, "users", user.uid);
				const userDoc = await getDoc(userDocRef);

				if (userDoc.exists()) {
					const userData = userDoc.data();

					// Merge user and party data from the root of the document
					const extendedUser: ExtendedUser = {
						...user, // Spread the properties of the Firebase user
						role: userData.role || null,
						restaurant: userData.restaurant || null,
						// Use all root fields directly from Firestore document
						street: userData.Street || userData.address?.street || null,
						postalNumber: userData.Zipcode || userData.address?.postalNumber || null,
						city: userData.City || userData.address?.city || null,
						country: userData.CountryCode || userData.address?.country || null,
						vatNumber: userData.VATNumber || userData.vatNumber || null,
						// Direct fields from Party data in the root document
						...userData
					};

					setCurrentUser(extendedUser); // Set the combined user and party data
				} else {
					// Handle missing Firestore data (optional)
					console.error("User data not found in Firestore");
					setCurrentUser(null);
				}
			} else {
				setCurrentUser(null); // Clear user data when logged out
			}
			setLoading(false); // Set loading to false after checking auth state
		});

		return unsubscribe;
	}, []);

	return (
		<AuthContext.Provider value={{ currentUser, loading }}>
			{!loading && children}
		</AuthContext.Provider>
	);
};
