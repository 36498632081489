import React, { useEffect, useState } from "react";
import logo from "../../../assets/img/logo-c.png";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {collection, doc, getDoc, getDocs, setDoc} from "firebase/firestore";
import {auth, firestore, functions} from "../../../firebaseConfig";
import {httpsCallable} from "firebase/functions";
import {Party} from "../../../types";

const Register = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [role, setRole] = useState("admin");
	const [restaurant, setRestaurant] = useState<string | null>(null);
	const [street, setStreet] = useState("");
	const [postalNumber, setPostalNumber] = useState("");
	const [city, setCity] = useState("");
	const [country, setCountry] = useState("");
	const [vatNumber, setVatNumber] = useState("");
	const [billitId, setBillitId] = useState<number | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [editingUserId, setEditingUserId] = useState<string | null>(null);
	const [users, setUsers] = useState<any[]>([]);
	const [billitData, setBillitData] = useState<any[] | null>(null);
	const [filteredBillitData, setFilteredBillitData] = useState<any>();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const querySnapshot = await getDocs(collection(firestore, "users"));
				const usersData = querySnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setUsers(usersData);
			} catch (err) {
				console.error("Failed to fetch users:", err);
			}
		};

		fetchUsers();
	}, []);

	useEffect(() => {
		const fetchBillitData = async () => {
			try {
				const getAllProducts = httpsCallable(functions, "getAllParties");
				const response = await getAllProducts() as any;
				const fetchedParties = response.data.Items;
				setBillitData(fetchedParties);
			} catch (error) {
				setError("Error fetching Billit data.");
				console.error("Error fetching Billit data:", error);
			}
		};

		fetchBillitData();
	}, []);



	useEffect(() => {
		if (billitId && billitData!.length > 0) {
			// Filter the fetched Billit data based on the selected Billit ID
			const filteredData = billitData!.find((party: Party) => party.PartyID === billitId);
			console.log(filteredData);
			setFilteredBillitData(filteredData || null); // Set filtered data or null if not found
		} else {
			setFilteredBillitData(null); // Reset if no Billit ID is selected
		}
	}, [billitId, billitData]); // Run this when billitId or billitData changes

	const handleEdit = (userId: string) => {
		const user = users.find((u) => u.id === userId);
		if (user) {
			setEditingUserId(userId);
			setEmail(user.email || "");
			setRole(user.role || "admin");
			setRestaurant(user.restaurant || null);
			setStreet(user.address?.street || "");
			setPostalNumber(user.address?.postalNumber || "");
			setCity(user.address?.city || "");
			setCountry(user.address?.country || "");
			setVatNumber(user.vatNumber || "");
			setBillitId(user.billitId || null);
		}
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		try {
			// Get reference to the user document (if editing an existing user)
			const userRef = editingUserId
				? doc(firestore, "users", editingUserId)
				: null;

			let userData: any = {
				email,
				role,
				restaurant: role === "owner" ? restaurant : null,
				vatNumber: role === "owner" ? vatNumber : null,
				billitId,
				// Overwrite the address field with Billit data
				address: {
					street: filteredBillitData?.Street || "",
					postalNumber: filteredBillitData?.PostalNumber || "",
					city: filteredBillitData?.City || "",
					country: filteredBillitData?.CountryCode || "",
				},
				// Other fields should be populated by Billit data if available
				...filteredBillitData,
			};

			console.log(userData);

			if (userRef) {
				// If editing an existing user, retrieve current user data from Firestore
				const existingUserDoc = await getDoc(userRef);
				if (existingUserDoc.exists()) {
					// Merge the current Firestore data with the new Billit data
					const existingData = existingUserDoc.data();
					userData = {
						...existingData,
						// Overwrite fields with Billit data if it exists
						email: email || existingData.email,
						role: role || existingData.role,
						restaurant: restaurant || existingData.restaurant,
						billitId: billitId || existingData.billitId,
						address: {
							street: filteredBillitData?.Street || existingData.address?.street || "",
							postalNumber: filteredBillitData?.PostalNumber || existingData.address?.postalNumber || "",
							city: filteredBillitData?.City || existingData.address?.city || "",
							country: filteredBillitData?.CountryCode || existingData.address?.country || "",
						},
						// Any other fields from Billit data should overwrite or be added if they don't exist
						...filteredBillitData,
					};
				}
			}

			// If no userRef (new user), create a new user in Firestore
			if (!editingUserId) {
				const userCredential = await createUserWithEmailAndPassword(auth, email, password);
				const user = userCredential.user;

				// Create new user document in Firestore with the user ID
				await setDoc(doc(firestore, "users", user.uid), userData);
				alert("User registered successfully!");
			} else {
				// Update existing user document with merged data
				await setDoc(userRef!, userData);
				alert("User updated successfully!");
			}

			// Reset form after submission
			setEditingUserId(null);
			setEmail("");
			setPassword("");
			setRole("admin");
			setRestaurant(null);
			setStreet("");
			setPostalNumber("");
			setCity("");
			setCountry("");
			setBillitId(null);
			setBillitData(null); // Clear Billit data after submission
			navigate("/admin");
		} catch (error) {
			setError("Failed to save user. Please try again.");
			console.error("Error saving user:", error);
		}
	};



	return (
		<div className="bg-black min-h-screen flex justify-center">
			<div className="w-1/2 bg-white flex justify-center items-center flex-col">
				<img src={logo} alt="Logo" className="w-24 mb-6" />
				<h1 className="font-beastie-bold text-4xl mb-6">Register a New User</h1>
				<form className="w-full p-8" onSubmit={handleSubmit}>
					<input
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="Email"
						className="w-full p-4 mb-4 border rounded"
						required
					/>
					<input
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						placeholder="Password"
						className="w-full p-4 mb-4 border rounded"
						disabled={!!editingUserId}
						required={!editingUserId}
					/>
					<select
						value={billitId || ""}
						onChange={(e) => setBillitId(parseInt(e.target.value))}
						className="w-full p-4 mb-4 border rounded"
					>
						<option value="" disabled>
							Select Billit ID
						</option>
						{billitData && billitData.map(item => (
							<option value={item.PartyID}>{item.Name}</option>
						))}
					</select>

					{/* Display Billit data preview */}
					{filteredBillitData && (
						<div className="mb-4">
							<h2 className="font-bold">Billit Data Preview</h2>
							<p><strong>Street:</strong> {filteredBillitData.Street}</p>
							<p><strong>City:</strong> {filteredBillitData.City}</p>
							<p><strong>Country:</strong> {filteredBillitData.CountryCode}</p>
							<p><strong>VAT Number:</strong> {filteredBillitData.VATNumber}</p>
						</div>
					)}

					<button
						type="submit"
						className="w-full p-4 bg-blue-600 text-white rounded hover:bg-blue-700"
					>
						{editingUserId ? "Update User" : "Register"}
					</button>
				</form>
			</div>

			<div className="w-1/2 bg-gray-100 p-8 mt-24">
				<h2 className="text-2xl font-bold mb-4">Existing Users</h2>
				<div className="flex flex-wrap gap-4">
					{users.map((user) => (
						<div
							key={user.id}
							className="w-full p-4 bg-white shadow rounded-lg flex justify-between items-center"
						>
							<div>
								<h3 className="text-lg font-semibold">{user.email}</h3>
								<p className="text-sm text-gray-500">{user.role}</p>
							</div>
							<button
								onClick={() => handleEdit(user.id)}
								className="text-blue-600 hover:underline"
							>
								Edit
							</button>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Register;
