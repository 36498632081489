import beastiePress from "../../../assets/img/stands/press.png";
import beastiePressRed from "../../../assets/img/stands/press-red.png";
import StandItem from "../../Home/StandItem";
import HeadingText from "../../../components/RevealText/RevealText";
import {useEffect} from "react";


function AdminHome() {

	// const {role} = useAuth();


	return (
		<div className="min-h-screen bg-white-lilac-50 flex justify-center items-center flex-col">
			<div className="w-[85%] mx-auto mt-32">
				<div className="">
					<h1 className="font-bold">Beastie Owners</h1>
				</div>
				<div className="grid grid-cols-3 gap-5">
					<HeadingText>
						<a href="/admin/sauces"
						   className="w-full border-2 rounded-lg hover:shadow-lg text-torch-red-500 bg-white flex flex-col justify-center items-center transition-all cursor-pointer">
							<div className="py-16 px-5 flex justify-center items-center flex-col">
								<h1 className="font-beastie text-7xl text-black">BEASTIE</h1>
								<h1 className="font-beastie-bold text-7xl">SAUCES</h1>
							</div>
						</a>
					</HeadingText>
					<HeadingText>
						<a href="/admin/register"
						   className="w-full border-2 rounded-lg hover:shadow-lg text-torch-red-500 bg-white flex flex-col justify-center items-center transition-all cursor-pointer">
							<div className="py-16 px-5 flex justify-center items-center flex-col">
								<h1 className="font-beastie text-7xl text-black">BEASTIE</h1>
								<h1 className="font-beastie-bold text-7xl">ADMINS</h1>
							</div>
						</a>
					</HeadingText>
				</div>

			</div>
			<div className="w-[85%] mx-auto my-5">
				<div className="">
					<h1 className="font-bold">Beastie Admins</h1>
				</div>
				<div className="grid grid-cols-3 gap-5">
					<HeadingText>
						<a href="/admin/press"
						   className="w-full border-2 rounded-lg hover:shadow-lg text-torch-red-500 bg-white flex flex-col justify-center items-center transition-all cursor-pointer">
							<div className="py-16 px-5 flex justify-center items-center flex-col">
								<h1 className="font-beastie text-7xl text-black">BEASTIE</h1>
								<h1 className="font-beastie-bold text-7xl">PRESS</h1>
							</div>
						</a>
					</HeadingText>
					<HeadingText>
						<a href="/admin/jobs"
						   className="w-full border-2 rounded-lg hover:shadow-lg text-torch-red-500 bg-white flex flex-col justify-center items-center transition-all cursor-pointer">
							<div className="py-16 px-5 flex justify-center items-center flex-col">
								<h1 className="font-beastie text-7xl text-black">BEASTIE</h1>
								<h1 className="font-beastie-bold text-7xl">JOBS</h1>
							</div>
						</a>
					</HeadingText>
					<HeadingText>
						<a href="/admin/menu"
						   className="w-full border-2 rounded-lg hover:shadow-lg text-torch-red-500 bg-white flex flex-col justify-center items-center transition-all cursor-pointer">
							<div className="py-16 px-5 flex justify-center items-center flex-col">
								<h1 className="font-beastie text-7xl text-black">BEASTIE</h1>
								<h1 className="font-beastie-bold text-7xl">MENU</h1>
							</div>
						</a>
					</HeadingText>
					<HeadingText>
						<a href="/admin/giveaway"
						   className="w-full border-2 rounded-lg hover:shadow-lg text-torch-red-500 bg-white flex flex-col justify-center items-center transition-all cursor-pointer">
							<div className="py-16 px-5 flex justify-center items-center flex-col">
								<h1 className="font-beastie text-7xl text-black">BEASTIE</h1>
								<h1 className="font-beastie-bold text-7xl">GIVEAWAY</h1>
							</div>
						</a>
					</HeadingText>
					<HeadingText>
						<a href="/admin/giveaway"
						   className="w-full border-2 rounded-lg hover:shadow-lg text-torch-red-500 bg-white flex flex-col justify-center items-center transition-all cursor-pointer">
							<div className="py-16 px-5 flex justify-center items-center flex-col">
								<h1 className="font-beastie text-7xl text-black">BEASTIE</h1>
								<h1 className="font-beastie-bold text-7xl">EVENTS</h1>
							</div>
						</a>
					</HeadingText>
					<HeadingText>
						<a href="https://checkpoint.beastie.be"
						   className="w-full border-2 rounded-lg hover:shadow-lg text-torch-red-500 bg-white flex flex-col justify-center items-center transition-all cursor-pointer">
							<div className="py-16 px-5 flex justify-center items-center flex-col">
								<h1 className="font-beastie text-7xl text-black">BEASTIE</h1>
								<h1 className="font-beastie-bold text-7xl">CHECKPOINT</h1>
							</div>
						</a>
					</HeadingText>
					<HeadingText>
						<a href="/admin/firmware"
						   className="w-full border-2 rounded-lg hover:shadow-lg text-torch-red-500 bg-white flex flex-col justify-center items-center transition-all cursor-pointer">
							<div className="py-16 px-5 flex justify-center items-center flex-col">
								<h1 className="font-beastie text-7xl text-black">BEASTIE</h1>
								<h1 className="font-beastie-bold text-7xl">FIRMWARE</h1>
							</div>
						</a>
					</HeadingText>
				</div>
			</div>

		</div>
	);
}

export default AdminHome
