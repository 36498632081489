import React, { useEffect, useState } from 'react';
import logo from "../../assets/img/logo-c.png";
import NavItem from "./NavItem";
import { motion, useScroll, useVelocity } from "framer-motion";
import { useAuth } from "../../AuthContext"; // Import useAuth hook
import { signOut } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import {Link} from "react-router-dom";

function RegularMenu() {
	const slideDistance = 100;
	const threshold = 350;

	const { scrollY } = useScroll();
	const scrollVelocity = useVelocity(scrollY);

	const [isScrollingBack, setIsScrollingBack] = React.useState(false);
	const [isAtTop, setIsAtTop] = React.useState(true);
	const [isInView, setIsInView] = React.useState(true);

	const { currentUser } = useAuth(); // Get current user

	const handleLogout = async () => {
		try {
			await signOut(auth);
			window.location.href = "/"; // Redirect to home page after logout
		} catch (error) {
			console.error("Failed to sign out:", error);
		}
	};

	useEffect(() => {
		scrollVelocity.onChange((latest) => {
			if (latest > 0) {
				setIsScrollingBack(false);
				return;
			}

			if (latest < -threshold) {
				setIsScrollingBack(true);
				return;
			}
		});
	}, []);

	useEffect(() => scrollY.onChange((latest) => setIsAtTop(latest <= 0)), []);

	useEffect(() => setIsInView(isScrollingBack || isAtTop), [isScrollingBack, isAtTop]);

	return (
		<motion.div
			className={`fixed z-[9999] w-full flex items-center ${isAtTop ? 'bg-gradient-to-b from-black' : 'bg-black'}`}
			animate={{ y: isInView ? 0 : -slideDistance, height: isAtTop ? 150 : slideDistance }}
			transition={{ duration: 0.2, delay: 0.25, ease: "easeInOut" }}
		>
			<div className="w-[85%] flex justify-between items-center mx-auto p-2">
				<div className="flex items-center">
					<a href="/"><img className="w-[50%] h-auto" src={logo} alt="Beastie Smash Burgers" /></a>
				</div>
				<div className="w-full flex-grow">
					<ul className="flex font-bold text-white gap-5 justify-end items-center">
						<NavItem name="Menu" link="menu/select" />
						<NavItem name="Our Story" link="story" />
						<NavItem name="Restaurants" link="/restaurants" />
						<NavItem name="Franchise" link="franchise" />
						<NavItem name="Community" link="community" />
						<NavItem name="Jobs" link="jobs" />
						{currentUser ? (
							<li
								className="bg-torch-red-500 font-beastie-bold text-nowrap tracking-wide uppercase px-4 py-2 rounded-xl border-2 border-transparent hover:border-torch-red-500 hover:bg-black hover:text-torch-red-500 hover:-rotate-6 transition-all cursor-pointer"
								onClick={handleLogout}
							>
								<span className="font-black">Logout</span>
							</li>
						) : (
							<li className="bg-torch-red-500  uppercase text-nowrap px-4 py-2 rounded-xl border-2 border-transparent hover:border-torch-red-500 hover:bg-black hover:text-torch-red-500 hover:-rotate-6 transition-all cursor-pointer">
								<Link to="/restaurants" className="font-black font-beastie-bold tracking-wide text-2xl">Book a table</Link>
							</li>
						)}
					</ul>
				</div>
			</div>
		</motion.div>
	);
}

export default RegularMenu;
