import React from "react";
import generic from "../../../assets/img/burgers/generic.svg";
import { Sauce } from "../../../types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

interface SauceItemProps {
	sauce: Sauce;
	quantity: number; // Current quantity in the cart
	onUpdateCart: (sauceId: string, quantity: number) => void; // Callback to update the cart
}

const SauceItem = ({ sauce, quantity, onUpdateCart }: SauceItemProps) => {
	const incrementQuantity = () => {
		onUpdateCart(sauce.id!, quantity + 1);
	};

	const decrementQuantity = () => {
		if (quantity > 0) {
			onUpdateCart(sauce.id!, quantity - 1);
		}
	};

	return (
		<div className="border border-gray-600 rounded-2xl flex flex-col justify-between items-center h-full">
			{/* Image */}
			<div className="w-full">
				<div className="relative w-[80%] mx-auto mt-5 flex justify-center items-center rounded-2xl p-5">
					<LazyLoadImage
						src={sauce.thumbnailUrl ? sauce.thumbnailUrl : generic}
						effect="blur"
						className="w-full h-[200px] object-cover z-[999]"
					/>
				</div>
			</div>

			{/* Content */}
			<div className="text-black px-5 mt-5 flex flex-col items-center w-full h-full">
				<h1 className="relative text-3xl flex flex-col items-center sm:text-4xl md:text-5xl py-2 font-beastie-bold text-center">
					{sauce.name}
				</h1>
				<div className="text-sm md:text-base mt-2 text-center w-full">
					{sauce.description || "No Description available"}
				</div>
				<div className="mt-3 w-full text-center">
					<div className="font-beastie-bold">Batch Size:</div>
					<div className="uppercase">{sauce.batchSize}</div>
				</div>
			</div>

			{/* Footer */}
			<div className="w-full px-5 py-3 mt-auto text-black">
				<div className="flex justify-between items-center">
					<div className="underline">Show Details</div>
					<div className="flex flex-col text-2xl sm:text-3xl">
						<div className="font-beastie-bold">PRICE</div>
						<div className="font-black">{sauce.price}€</div>
					</div>
				</div>

				{/* Cart Actions */}
				<div className="flex justify-between mt-3">
					<button
						onClick={decrementQuantity}
						disabled={quantity === 0}
						className="px-4 py-2 bg-red-500 text-white rounded disabled:opacity-50"
					>
						-
					</button>
					<span className="px-4 py-2">{quantity}</span>
					<button
						onClick={incrementQuantity}
						className="px-4 py-2 bg-green-500 text-white rounded"
					>
						+
					</button>
				</div>
			</div>
		</div>
	);
};

export default SauceItem;
